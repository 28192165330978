import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../ui/Wrapper';
import consentTypes from '../../utils/constants/consentTypes';
import consentService from '../../services/consentService';
import Label from '../ui/form/label/label';
import { Error, FormGroup } from '../ui/form';
import Anchor from '../ui/Anchor';
import useSiteLocation from '../../hooks/useSiteLocation';

const sortByName = (a, b) => a.name.localeCompare(b.name);

const ConsentArea = ({ type, onLoad, getFieldProps, errors, touched, submitCount, setFieldValue }) => {
  const [consentData, setConsentData] = useState();
  const { rootPath } = useSiteLocation();

  const fetchPurposes = useCallback(async () => {
    try {
      const { data } = await consentService.purposes(type);
      setConsentData(data);
      onLoad(data?.purposes);
    } catch (error) {
      console.error(error);
    }
  }, [type]);

  useEffect(() => {
    fetchPurposes();
  }, [fetchPurposes]);

  const handleChange = (name, value) => {
    setFieldValue(name, value);
  };

  const mapPurposeItem = purpose => {
    const name = `consents.${purpose.code}`;

    const consentError = errors.consents || {};
    const consentTouched = touched.consents || {};

    return (
      <FormGroup mb="10px">
        <input
          type="checkbox"
          onChange={({
            event: {
              target: { checked },
            },
          }) => handleChange(name, checked)}
          {...getFieldProps(name)}
          name={name}
          key={name}
          id={name}
        />
        <Label name={name} required={purpose.required}>
          {purpose.name}
        </Label>
        {consentError[purpose.code] && (consentTouched[purpose.code] || submitCount >= 1) ? (
          <Error mt="0">{`${consentError[purpose.code]}`}</Error>
        ) : null}
      </FormGroup>
    );
  };

  return consentData ? (
    <Row>
      <Col lg={12}>{consentData.purposes.sort(sortByName).map(mapPurposeItem)}</Col>
      <Col lg={12} mb="20px">
        <span>
          {consentData.has_terms ? (
            <Anchor
              path={`${rootPath}/termos-de-uso`}
              target="_blank"
              fontWeight="800"
              color="primaryLight"
              hover={{ color: 'secondary' }}
              allowBlank
            >
              Termos de uso
            </Anchor>
          ) : null}
          {consentData.has_terms && consentData?.has_policies ? ' e ' : null}
          {consentData.has_policies ? (
            <Anchor
              path={`${rootPath}/politica-de-privacidade`}
              target="_blank"
              fontWeight="800"
              color="primaryLight"
              hover={{ color: 'secondary' }}
              allowBlank
            >
              Política de privacidade
            </Anchor>
          ) : null}
        </span>
      </Col>
    </Row>
  ) : null;
};

ConsentArea.propTypes = {
  type: PropTypes.oneOf([consentTypes.CONTACT_US, consentTypes.SCHOLARSHIP, consentTypes.LEADS_CAPTURE]).isRequired,
  onLoad: PropTypes.func.isRequired,
  getFieldProps: PropTypes.func.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  submitCount: PropTypes.number,
};

ConsentArea.defaultProps = {
  errors: {
    consent: {},
  },
  touched: {
    consent: {},
  },
  submitCount: 0,
};

export default ConsentArea;
